<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="130"
  >
    <v-container class="text-right d-flex align-center">
      <v-row>
        <v-col
          sm="12"
          md="6"
        >
          <v-img
            src="@/assets/vincss-logo-text-white-cloud.png"
            aspect-ratio="3"
            contain
          />
        </v-col>
      </v-row>

      <v-spacer />

      <v-btn
        v-if="this.$store.getters.isLoggedIn"
        min-height="48"
        min-width="50"
        text
        to="/"
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          v-text="dashboard.icon"
        />
        <span
          class="hidden-sm-and-down"
          v-text="dashboard.text"
        />
      </v-btn>

      <v-btn
        v-if="!this.$store.getters.isLoggedIn"
        min-height="48"
        min-width="50"
        text
        :to="register.to"
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          v-text="register.icon"
        />
        <span
          class="hidden-sm-and-down"
          v-text="register.text"
        />
      </v-btn>

      <v-btn
        v-if="!this.$store.getters.isLoggedIn"
        :to="login.to"
        min-height="48"
        min-width="50"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          v-text="login.icon"
        />

        <span
          class="hidden-sm-and-down"
          v-text="login.text"
        />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'PagesCoreAppBar',

    data: () => ({
      register: {
        icon: 'mdi-fingerprint',
        text: 'Register',
        to: '/pages/register',
      },
      login: {
        icon: 'mdi-lock-open',
        text: 'Login',
        to: '/pages/login',
      },
      dashboard: {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        to: '/',
      },
      titles: {
        '/pages/login': 'Login Page',
        '/pages/register': 'Register Page',
        '/': 'Dashboard',
      },
    }),

    computed: {
      title () {
        return this.titles[this.$route.path]
      },

      toDashboard: function () {
        // console.log('store admin', this.$store.getters.isAdmin)
        return this.$store.getters.isAdmin ? '/' : '/user/dashboard'
      },
    },
  }
</script>
